"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actions_1 = require("../actions");
const redux_1 = require("redux");
const initialNavState = { navOpened: false };
function navStatus(state = initialNavState, action) {
    switch (action.type) {
        case actions_1.TOGGLE_NAV_MENU:
            return { navOpened: action.status };
    }
    return state;
}
exports.rootReducer = redux_1.combineReducers({
    navStatus
});
