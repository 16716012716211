"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actions_1 = require("../actions");
exports.navMiddleware = (store) => (next) => (action) => {
    if (action.type === actions_1.TOGGLE_NAV_MENU) {
        if (action.status) {
            document.body.classList.add('nav-opened');
        }
        else {
            document.body.classList.remove('nav-opened');
        }
    }
    next(action);
};
