"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const react_redux_1 = require("react-redux");
const actions_1 = require("../actions");
function MenuItem(props) {
    const dispatch = react_redux_1.useDispatch();
    return (React.createElement("div", { className: "container flex reverse" },
        React.createElement(react_router_dom_1.Link, { to: props.link, className: "h4", onClick: () => dispatch(actions_1.toggleNav(false)) }, props.name)));
}
function Menu(props) {
    return (React.createElement("div", { className: "menu", style: props.navState ? { opacity: '1', visibility: 'visible' } : { opacity: '0', visibility: 'hidden' } },
        React.createElement(MenuItem, { link: "/", name: "Home" }),
        React.createElement(MenuItem, { link: "/about", name: "About" }),
        React.createElement(MenuItem, { link: "/resume", name: "Resume" }),
        React.createElement(MenuItem, { link: "/contact", name: "Contact" })));
}
exports.default = Menu;
